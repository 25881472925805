import { useMemo } from 'react'
import { useSession } from '../../utils/auth'
import { decodeKeycloakJWT } from '../../utils/keycloak'

type RolesState = {
  data: string[]
  isLoading: boolean
}

export function useRoles(): RolesState {
  const { session, loading } = useSession()
  return useMemo(() => {
    if (!session?.access_token) {
      return { data: [], isLoading: loading }
    }

    const decodedJWT = decodeKeycloakJWT(session.access_token)

    if (!decodedJWT.realm_access.roles) {
      throw new Error('Error parsing roles from JWT')
    }

    return {
      data: Object.values(decodedJWT.realm_access.roles),
      isLoading: loading
    }
  }, [session, loading])
}

export function useHasRoles<T extends string>(requestedRoles: T[]) {
  const { data } = useRoles()
  return requestedRoles.reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: data.includes(cur)
    }
  }, {} as Record<T, boolean>)
}
