import { useRoles } from 'driverama-core/auth/openid/useRoles'
import { addSearchParams } from 'driverama-core/utils/searchParams'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { useUpdateEffect } from 'react-use'
import { AppAccessRole } from './utils'

interface Props {
  children: ReactNode
  accessRoleToCheck: AppAccessRole
  logoutUrl: string
}

export function AccessCheck({ children, accessRoleToCheck, logoutUrl }: Props) {
  const router = useRouter()
  const { data: roles, isLoading } = useRoles()

  useUpdateEffect(() => {
    // if user does not have requested access app role -> logout
    async function checkAccessRights() {
      if (!roles.includes(accessRoleToCheck)) {
        await router.push(
          addSearchParams(logoutUrl, {
            redirectReason:
              'Insufficient access rights. Contact your supervisor.'
          })
        )
      }
    }

    if (!isLoading) {
      checkAccessRights()
    }
  }, [isLoading, roles])

  return <div>{children}</div>
}
